import React, { useState, useEffect } from 'react';
import * as routes from "../../../Router/RoutesURL";
import * as textMessage from "../../../Config/Constant";
import redCross from "../../../assets/images/CommonComponent/redCross.svg";
import greenPlus from '../../../assets/images/CommonComponent/greenPlus.svg';
import Geentick from '../../../assets/images/CommonComponent/Geentick.svg';
import AppContainer from '../../../components/AppContainer/AppContainer';
import './AddMember.scss';
import Select from "react-select";
import { addNewTeamMember, getUserRoleList, viewTeamMemberDetails, updateTeamMemberDetails, getDepartmentList } from '../../../redux/actions/UserManagementAction/TeamMembersAction/TeamMembersAction';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const AddMember=(props)=> {
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
    })
    const { history } = props;
    const location = useLocation();
    const selectionType = location?.state?.selection
    const dispatch = useDispatch();
    const { firstName, lastName, email } = state;
    const [userListRole, setUserListRole] = useState(null);
    const [userRoleID, setUserRoleID] = useState();
    const [userRoleTitle, setUserRoleTitle] = useState("");
    const [phoneNumber, setPhoneNumber] = useState();
    const [newRoleID, setNewRoleID] = useState("");
    const [newRoleTitle, setNewRoleTitle] = useState("");
    const [department, setDepartment] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [formErrorsForEdit, setFormErrorsForEdit] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [somChange, setSomChange] = useState();
    const [gumnutStaffID, setGumnutStaffID] = useState();
    const getUserList = useSelector(state => state?.getUserRoleList?.getUserRoleList);
    const addNewTeamMemberReducer = useSelector(state=> state?.addNewTeamMember)
    const updateTeamMemberDetailsReducer = useSelector(state=> state?.updateTeamMemberDetails)
    const {departments} = useSelector(state => state?.getDepartments);
    const TeamMemberListData = getUserList?.data;
    const {loading} = useSelector(state => state?.viewTeamMemberDetails);
    const viewTeamMember = useSelector(state => state?.viewTeamMemberDetails?.viewTeamMemberDetails);
    const viewTeamMemberDetailsData = viewTeamMember?.data;

    const navigate = useNavigate()

    const handleChanges = (value, country) => {
        if (value.startsWith(country.dialCode)) {
            value = value.substring(country.dialCode.length);
        }
        if(value && "+" + country.dialCode + - + value !== viewTeamMember?.data?.phone_number )
            setSomChange(true)
        else
            setSomChange(false)
        setPhoneNumber("+" + country.dialCode + - + value)
    }

    const handleChange = (e) => {
        if (e?.target?.name != undefined) {
            let { name, value } = e.target;
            setState({ ...state, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        if (phoneNumber !== "" || email !== "" || firstName !== "" || lastName !== "" || newRoleTitle !== "") {
            setFormErrorsForEdit(validateEdit(state));
        }
        setIsSubmit(true);
        if (selectionType === "edit") {
            let errors = validateEdit(state)

            e.preventDefault();
            setFormErrorsForEdit(errors);
            console.log(errors)
            if (Object.keys(errors).length === 0 && (phoneNumber !== "" && email !== "" && firstName !== "" && lastName !== "" && newRoleTitle !== "")) {
                const sendRequest = {
                    "first_name": firstName,
                    "last_name": lastName,
                    "email_id": email,
                    "phone_number": phoneNumber,
                    "user_id": location?.state?.selectedMemberId,
                    "role_id": newRoleID,
                    "role_title": newRoleTitle?.value,
                    "department_id": department?.value ? department?.value : null,
                    "gumnut_staff_id": gumnutStaffID ?  gumnutStaffID : null
                };
                dispatch(updateTeamMemberDetails(sendRequest, navigate));

            }
        }

        if (selectionType === "add") {
            let errors = validate(state)
            if (state.email !== "" || phoneNumber == "" || userRoleTitle !== "") {
                setFormErrors(errors);
            }
            e.preventDefault();
            setFormErrors(errors);

            if (Object.keys(errors).length === 0) {
                const sendRequest = {
                    "first_name": state.firstName,
                    "last_name": state.lastName,
                    "email_id": state.email,
                    "phone_no": phoneNumber,
                    "user_role_id": userRoleID,
                    "department_id": department?.value ? department?.value : null,
                    "gumnut_staff_id": gumnutStaffID ?  gumnutStaffID : null
                };

                dispatch(addNewTeamMember(sendRequest));
                setState(state.firstName = "");
                setState(state.lastName = "");
                setState({ ...state, "email": "" });
                setPhoneNumber("")
                setUserRoleTitle("")
                setDepartment('')
                setGumnutStaffID('')
                setIsSubmit(false)
            }
            if (!firstName || !lastName || !email || !phoneNumber) {
                return;
            }
        }
    }

    const handleChangeFname = (val) => {
        const regexName = textMessage.NAME_REGEX;;
        const nameFormat = regexName.test(val);
        if (val === "") {
            setState({ ...state, "firstName": val });
        } else if (nameFormat && val.length < 40) {
            setState({ ...state, "firstName": val });
        }
    }

    const handleChangeLname = (val) => {
        const regexName = textMessage.NAME_REGEX;;
        const nameFormat = regexName.test(val);
        if (val === "") {
            setState({ ...state, "lastName": val });
        } else if (nameFormat && val.length < 40) {
            setState({ ...state, "lastName": val });
        }
    }

    const handleChangeLevel = (e) => {
        setUserRoleID(e.id)
        setNewRoleID(e.key)
        setUserRoleTitle(e)
        setNewRoleTitle(e)
        if(e.id!==userRoleID)
            setSomChange(true)
    }

    const handleChangeDepartment = (e)=>{
        if(!e.value)
            setDepartment(null)
        else        
            setDepartment(e)

        if(e.value!==department?.value)
            setSomChange(true)
    }

    const handleChangeGumnutID=(val)=>{
        setGumnutStaffID(val)
        setSomChange(true)
    }

    const validate = (values) => {
        const errors = {};

        if (!values.email) {
            errors.email = textMessage.EMAIL_IS_REQUIRED;
        } else if (!textMessage.EMAIL_REGEX.test(values.email)) {
            errors.email = textMessage.THIS_IS_NOT_A_VALID_EMAIL_FORMAT;
        }

        if (phoneNumber == "") {
            errors.phoneNumber = textMessage.PHONE_NUMBER_IS_REQUIRED;
        } else if (!textMessage.MOBILE_NUMBER_REGEX.test(phoneNumber)) {
            errors.phoneNumber = textMessage.THIS_IS_NOT_A_VALID_PHONE_NUMBER_FORMAT;
        } 
        
        if (userRoleTitle == "") {
            errors.userRoleTitle = textMessage.USER_ROLE_IS_REQUIRED;
        }

        return errors;
    };

    const validateEdit = () => {
        const errorsForEdit = {};
        const regex = textMessage.EMAIL_REGEX;
        if (email == "") {
            errorsForEdit.email = textMessage.EMAIL_IS_REQUIRED;
        } else if (!regex.test(email)) {
            errorsForEdit.email = textMessage.THIS_IS_NOT_A_VALID_EMAIL_FORMAT;
        }

        if (firstName == "") {
            errorsForEdit.firstName = textMessage.FIRST_NAME_IS_REQUIRED;
        }

        if (lastName == "") {
            errorsForEdit.lastName = textMessage.LAST_NAME_IS_REQUIRED;
        } 
        
        if (newRoleTitle == "") {
            errorsForEdit.newRoleTitle = textMessage.USER_ROLE_IS_REQUIRED;
        }

        if (phoneNumber == "") {
            errorsForEdit.phoneNumber = textMessage.PHONE_NUMBER_IS_REQUIRED;
        } else if (!textMessage.MOBILE_NUMBER_REGEX.test(phoneNumber)) {
            errorsForEdit.phoneNumber = textMessage.THIS_IS_NOT_A_VALID_PHONE_NUMBER_FORMAT;
        }

        return errorsForEdit;
    };

    const goBack = (e) => {
        if (selectionType === "add") {
            setState(state.firstName = "");
            setState(state.lastName = "");
            setState({ ...state, "email": "" });
            setPhoneNumber("")
            setUserRoleTitle("")
            navigate(routes.USER_MANAGEMENT, { state: { selectedTab: "TeamMembers", path: "add" } })
        }

        if (selectionType === "edit") {
            navigate(routes.USER_MANAGEMENT, { state: { selectedTab: "TeamMembers", path: "add" } })
        }
    };

    useEffect(()=>{
        if (selectionType === "edit") 
            setFormErrorsForEdit(validateEdit(state));
        else
            setFormErrors(validate(state));
    }, [state, phoneNumber])

    useEffect(() => {
        if (selectionType === "edit") {
            const sendRequest = {
                "user_id": location?.state?.selectedMemberId,
                "user_type": location?.state?.selectedRoleTitle
            };
            dispatch(viewTeamMemberDetails(sendRequest));
        }

        const sendRequest = {
            "pageSize": "",
            "is_team_list_req": 1
        }
        dispatch(getUserRoleList(sendRequest));
        dispatch(getDepartmentList())

    }, []);


    useEffect(() => {
        if (TeamMemberListData) {
            const data = TeamMemberListData?.filter((e)=> e.id !== '')?.map((item) => ({
                ...item,
                value: item.role_title,
            }));
            setUserListRole(data);
        } else {
            setUserListRole([]);
        }
    }, [TeamMemberListData, userRoleID, departments]);

    useEffect(() => {
        if (viewTeamMemberDetailsData && selectionType === "edit") {
            setState({
                firstName: viewTeamMember?.data?.first_name || "",
                lastName: viewTeamMember?.data?.last_name || "",
                email: viewTeamMember?.data?.email_id || "",             
            })
            setPhoneNumber(viewTeamMember?.data?.phone_number)
            setNewRoleID(viewTeamMember?.data?.role_id)
            setNewRoleTitle({ label: viewTeamMember?.data?.role_title, value: viewTeamMember?.data?.role_title, key: viewTeamMember?.data?.role_id })
            setGumnutStaffID(viewTeamMember?.data?.gumnut_staff_id)
            if(viewTeamMember?.data?.department_id)
                setDepartment({label: viewTeamMember?.data?.department_name, value: parseInt(viewTeamMember?.data?.department_id), key: parseInt(viewTeamMember?.data?.department_id)})
            else
                setDepartment(null)
        }
    }, [viewTeamMemberDetailsData,  loading]);

    return (
        <AppContainer history={history}>
            <div className="event-content">
                {selectionType === "add" ?
                    <>
                        <div className='AddMember'>
                            <form className="" onSubmit={handleSubmit} >
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className='mb-0'>Add New Member</h4>
                                        {firstName !== '' && lastName !== '' && email !== '' && phoneNumber !== "" && userRoleTitle !== "" && !addNewTeamMemberReducer?.loading  ? <div className=''>
                                            
                                            {
                                                !addNewTeamMemberReducer?.loading &&
                                                <button type="button" className="btn btn-danger redButton mr-3" onClick={goBack}>
                                                    <img className="mainIcon mr-1" src={redCross} alt="" width="22px" height="22px" />
                                                CANCEL</button>
                                            }
                                            
                                            <button type="submit" className="btn btn-success greenButton">
                                                {
                                                    addNewTeamMemberReducer.loading ?
                                                    <>
                                                        <CircularProgress size={30} sx={{'color': '#83C52B'}}/>
                                                    </>
                                                    :
                                                    <>
                                                        <img className="mainIcon mr-2" src={greenPlus} alt="" width="16px" height="16px" /> ADD
                                                    </>
                                                }
                                            </button>
                                        </div> : " "}
                                    </div>
                                    <div className="separator"></div>
                                    <div className="card-body ">
                                        <div className='row cardDiv'>
                                            <div className='col'>
                                                <div className='d-flex'>
                                                    <div className="mb-1 pr-0">

                                                        <label htmlFor="exampleFormControlInput1" className="form-label Label">First Name*</label>
                                                        <input
                                                            className="form-control Title"
                                                            type="text"
                                                            name='firstName'
                                                            value={firstName}

                                                            onChange={(event) => handleChangeFname(event.target.value)}
                                                            placeholder="Enter first name"

                                                        />
                                                        <div id='emailError' className='small'>{isSubmit && formErrors.firstName}</div>
                                                    </div>
                                                    <div className="mb-1 d-flex ml-4">
                                                        <div className="mb-3 pr-0">
                                                            <label htmlFor="exampleFormControlInput1" className="form-label Label">Last Name*</label>
                                                            <input
                                                                className="form-control Title"
                                                                type="text"
                                                                placeholder="Enter last name"
                                                                name='lastName'
                                                                value={lastName}
                                                                onChange={(event) => handleChangeLname(event.target.value)}

                                                            />
                                                            <div id='emailError' className='small'>{isSubmit && formErrors.lastName}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex'>
                                                    <div className="mb-1 pr-0">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label Label">Mobile No.*</label>
                                                        <div className="mb-3 pr-0  MobileDiv">
                                                            <PhoneInput
                                                                inputExtraProps={{
                                                                    required: true,
                                                                    autoFocus: false,
                                                                }}
                                                                className="registerInputType"
                                                                country={'in'}
                                                                placeholder="Mobile number"
                                                                value={phoneNumber}
                                                                onChange={handleChanges}
                                                            />
                                                            <div id='emailError' className='small'>{isSubmit && formErrors.phoneNumber}</div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-1 d-flex ml-4">
                                                        <div className="mb-3 pr-0">
                                                            <label htmlFor="exampleFormControlInput1" className="form-label Label">Email Address*</label>
                                                            <input
                                                                className="form-control Title"
                                                                placeholder="Enter email address"
                                                                value={email}
                                                                autoComplete='Off'
                                                                name='email'
                                                                onChange={handleChange}
                                                            />
                                                            <div id='emailError' className='small'>{isSubmit && formErrors.email}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex'>
                                                    <div className="mb-1 pr-0">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label Label">Select User Role*</label>
                                                        <Select
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            value={userRoleTitle}
                                                            required={true}
                                                            options={userListRole}
                                                            onChange={handleChangeLevel}
                                                            getOptionLabel={(e) => (
                                                                <span>{e.role_title}</span>
                                                            )}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#F5F5F5',
                                                                    primary: '#74613C',
                                                                },
                                                            })}
                                                        />
                                                        <div id='emailError' className='small'>{isSubmit && formErrors.userRoleTitle}</div>
                                                    </div>
                                                    <div className="mb-1 pr-0 ml-4">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label Label">Select Department</label>

                                                        <Select
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            value={department}
                                                            options={departments?.map((department) => {
                                                                return {

                                                                    label: department.name,
                                                                    value: department.id,
                                                                    key: department.id
                                                                };
                                                            })}
                                                            onChange={handleChangeDepartment}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#F5F5F5',
                                                                    primary: '#74613C',
                                                                },
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='d-flex'>
                                                    <div className="mb-3 pr-0 ">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label Label">Gumnut Staff ID</label>
                                                        <input
                                                            className="form-control Title"
                                                            min="0" 
                                                            name='gumnutStaffID'
                                                            type="text"
                                                            //maxlength="9" 
                                                            value={gumnutStaffID}
                                                            onKeyPress={(event)=> {
                                                                    const charCode = event.charCode;
                                                                    function isAlphaNumeric(charCode) {
                                                                        return (
                                                                            (charCode >= 48 && charCode <= 57) || // 0-9
                                                                            (charCode >= 65 && charCode <= 90) || // A-Z
                                                                            (charCode >= 97 && charCode <= 122)   // a-z
                                                                        );
                                                                    }
                                                                        
                                                                    if (!isAlphaNumeric(charCode)) {
                                                                        event.preventDefault();
                                                                    }                                                                        
                                                                }
                                                            }
                                                            onChange={(event) => handleChangeGumnutID(event.target.value)}
                                                            placeholder="Gumnut Staff ID"

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
                    :
                    //------------For Edit------------
                    <>
                        <div className='AddMember'>
                            {
                                loading && 
                                <div className='loader'>
                                    <CircularProgress sx={{'color': '#74613C'}}/>
                                </div>
                            }
                            <form className="" onSubmit={handleSubmit} >
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className='mb-0'>Edit Member</h4>
                                        <div className=''>
                                            {
                                                !updateTeamMemberDetailsReducer?.loading &&
                                                <button type="button" className="btn btn-danger redButton mr-3" onClick={goBack}>
                                                <img className="mainIcon mr-1" src={redCross} alt="" width="22px" height="22px" />
                                                CANCEL</button>
                                            }
                                            <button type="submit" className="btn btn-success greenButton">
                                                {
                                                    updateTeamMemberDetailsReducer.loading ?
                                                    <>
                                                        <CircularProgress size={30} sx={{'color': '#83C52B'}}/>
                                                    </>
                                                    :
                                                    <>
                                                    <img className="mainIcon mr-2" src={Geentick} alt="" width="16px" height="16px" />UPDATE
                                                    </>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                    <div className="separator"></div>
                                    <div className="card-body ">
                                        <div className='row cardDiv'>
                                            <div className='col'>
                                                <div className='d-flex'>
                                                    <div className="mb-1 pr-0">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label Label">First Name*</label>
                                                        <input
                                                            className="form-control Title"
                                                            type="text"
                                                            name='firstName'
                                                            value={firstName}
                                                            onChange={(event) => handleChangeFname(event.target.value)}
                                                            placeholder="Enter first name"
                                                        />
                                                        <div id='emailError' className='small'>{isSubmit && formErrorsForEdit.firstName}</div>
                                                    </div>
                                                    <div className="mb-1 d-flex ml-4">
                                                        <div className="mb-3 pr-0">
                                                            <label htmlFor="exampleFormControlInput1" className="form-label Label">Last Name*</label>
                                                            <input
                                                                className="form-control Title"
                                                                type="text"
                                                                placeholder="Enter last name"
                                                                name='lastName'
                                                                value={lastName}
                                                                onChange={(event) => handleChangeLname(event.target.value)}
                                                            />
                                                            <div id='emailError' className='small'>{formErrorsForEdit.lastName}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex'>
                                                    <div className="mb-3 pr-0">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label Label">Mobile No.*</label>
                                                        <div className="mb-1 pr-0 MobileDiv">
                                                            <PhoneInput
                                                                className="registerInputType"
                                                                country={'in'}
                                                                value={phoneNumber}
                                                                onChange={handleChanges}
                                                            />
                                                        </div>
                                                        <div id='emailError' className='small'>{isSubmit && formErrorsForEdit.phoneNumber}</div>
                                                    </div>
                                                    <div className="mb-3 d-flex ml-4">
                                                        <div className="mb-3 pr-0">
                                                            <label htmlFor="exampleFormControlInput1" className="form-label Label">Email Address*</label>
                                                            <input
                                                                className="form-control Title"
                                                                placeholder="Enter email address"
                                                                value={email}
                                                                name='email'
                                                                autoComplete='Off'
                                                                onChange={handleChange}
                                                            />
                                                            <div id='emailError' className='small'>{formErrorsForEdit.email}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex'>
                                                    <div className="mb-3 pr-0">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label Label">Select User Role*</label>

                                                        <Select
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            value={newRoleTitle}
                                                            options={TeamMemberListData?.filter((e)=> e.id !== '')?.map((guest) => {
                                                                return {

                                                                    label: guest.role_title,
                                                                    value: guest.role_title,
                                                                    key: guest.id
                                                                };
                                                            })}
                                                            onChange={handleChangeLevel}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#F5F5F5',
                                                                    primary: '#74613C',
                                                                },
                                                            })}
                                                        />
                                                        <div id='emailError' className='small'>{formErrorsForEdit.newRoleTitle}</div>
                                                    </div>
                                                    <div className="mb-3 pr-0 ml-4">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label Label">Select Department</label>

                                                        <Select
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            value={department}
                                                            options={[
                                                                {
                                                                    label: 'None',
                                                                    value: "",
                                                                    key: ''
                                                                },
                                                                ...departments?.map((department) => {
                                                                return {
                                                                    label: department.name,
                                                                    value: department.id,
                                                                    key: department.id
                                                                };
                                                            }),
                                                        ]}
                                                            onChange={handleChangeDepartment}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#F5F5F5',
                                                                    primary: '#74613C',
                                                                },
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='d-flex'>
                                                    <div className="mb-3 pr-0 ">
                                                        <label htmlFor="exampleFormControlInput1" className="form-label Label">Gumnut Staff ID</label>
                                                        <input
                                                            className="form-control Title"
                                                            min="0" 
                                                            name='gumnutStaffID'
                                                            type="text"
                                                            //maxlength="9" 
                                                            value={gumnutStaffID}
                                                            onKeyPress={(event)=> {
                                                                    const charCode = event.charCode;
                                                                    function isAlphaNumeric(charCode) {
                                                                        return (
                                                                            (charCode >= 48 && charCode <= 57) || // 0-9
                                                                            (charCode >= 65 && charCode <= 90) || // A-Z
                                                                            (charCode >= 97 && charCode <= 122)   // a-z
                                                                        );
                                                                    }
                                                                        
                                                                    if (!isAlphaNumeric(charCode)) {
                                                                        event.preventDefault();
                                                                    }                                                                        
                                                                }
                                                            }
                                                            onChange={(event) => handleChangeGumnutID(event.target.value)}
                                                            placeholder="Gumnut Staff ID"

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>}
            </div>
        </AppContainer >
    )
}
export default AddMember
